import React from 'react';
import NavMenu from './NavMenu';
import Image from '../img/contactsection.jpg';
import Logo from '../img/acidwineslogo3.png';
import Barrels from '../img/barrels3.jpg';
import TextBackGroundLow from '../img/backgroundlow.png';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, Modal, Paper, CardMedia, CardContent, CardActionArea, CardActions, Button, Fade } from '@material-ui/core';
import { Element } from 'react-scroll'
import Grower1 from '../img/grower1.jpg'
import { FaFacebookSquare, FaInstagram, FaCentercode } from 'react-icons/fa';

const maxContentWidth = 1200;

const styles = theme => ({
    navGrid: {
        maxWidth: maxContentWidth,
        width: "100%"
    },
    paperContainer: {
        maxWidth: maxContentWidth,
        paddingBottom: 20,
        paddingTop: 0,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        height: "100%"
    },
    winesContainer: {
        backgroundImage: `url(${TextBackGroundLow})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        maxWidth: 600,
        minHeight: 300,
        paddingBottom: 50,
        paddingTop: 50,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        // height: "100%",
        display: 'flex',
        // flexGrow: 1,
    },
    bodyContainer: {
        minHeight: '100vh',
        height: '100% !important'
    },
    fullHeightContainer: {
        minHeight: '100vh',
        // maxHeight: '100vmin'
    },
    stretchHeightContainer: {
        height: '100%',
        // flexGrow: 1,
        // maxHeight: '100vmin'
    },
    fullWidthBarrels: {
        backgroundImage: `url(${Barrels})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '20vh',
        maxHeight: '50vh',
        height: "100%",
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexGrow: 1,
        backgroundPosition: "center",
    },
    card: {
        // maxWidth: 500,
        // margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    title: {
        // flexGrow: 1,
        maxWidth: maxContentWidth,
        paddingBottom: 5,
        paddingTop: 25
    },
    growersRoot: {
        flexGrow: 1,
        maxWidth: maxContentWidth,
        paddingBottom: 20
    },
    gridFlexGrow: {
        flexGrow: 1,
    },
    contactOverlay: {
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${Image})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "center",
            minHeight: 400,
            maxWidth: 600,
        },
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        // height: "100%",
        display: 'flex',
        // flexGrow: 1,
    },
    blackOnSmall: {
        color: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.secondary.main
        }
    },
    logo: {
        backgroundImage: `url(${Logo})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        maxWidth: 300,
        // minWidth: 300,
        maxHeight: 120,
        minHeight: 80,
        display: 'flex',
        width: '100%',
        height: '100%',
        flexGrow: 1,
    },
    modalStyle: {
        top: `50%`,
        left: `50%`,
        textAlign: 'center',
        position: 'absolute',
        transform: `translate(-50%, -50%)`,
        paddingTop: 60,
        paddingBottom: 60,
        paddingLeft: 30,
        paddingRight: 30,
    },
});

class Home extends React.Component {
    state = {
        modalOpen: true,
        showFirstItem: false,
        showSecondItem: false,
        showThirdItem: false,
    };

    render() {
        const { classes } = this.props;
        return (<div>
            <Grid container direction="column" justify="center" display='flex' alignItems='center'>

                <Grid container direction="column" className={classes.fullHeightContainer} name="home-section" id="home-section" alignItems='center' justify="space-between">
                    <Fade in={this.state.showThirdItem} timeout={3000}>
                        <Grid className={classes.navGrid} item>
                            <NavMenu />
                        </Grid>
                    </Fade>
                    <Grid container direction="column" justify="center" display='flex' alignItems='center'>
                        <Grid container direction="column" className={classes.paperContainer} alignItems='center' justify='center'>
                            <Grid item className={classes.title}>
                                <Fade in={this.state.showFirstItem} timeout={1000}>
                                    <Typography><h3 >Acid Wines</h3></Typography>
                                </Fade>
                            </Grid>
                            <Fade in={this.state.showSecondItem} timeout={2000}>
                                <Grid item>
                                    <Typography>Acid Wines is an importer of natural wines from Chile, where geography and weather create perfect conditions for naturally-produced wines. We bring in handcrafted wines that reflect the terroir and are made from organic or biodynamic grapes, without strange additives. Simply put, as wines have been made in Chile for hundreds of years.</Typography>
                                    <br />
                                    <Typography>Our wines come from small-scale producers who care for the environment and take social responsibility. In regions that are sensitive to drought, we work with producers who avoid artificial irrigation as much as possible.</Typography>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                    <Fade in={this.state.showThirdItem} timeout={3000}>
                        <Grid container className={classes.fullWidthBarrels} >
                            {/* <Grid item className={classes.fullWidthBarrels} justify="flex-end" alignItems='flex-end'>
                            </Grid> */}
                        </Grid>
                    </Fade>
                </Grid>
                <Grid name="growers-section" id="growers-section" className={classes.fullHeightContainer} container direction="column" justify="center" display='flex' alignItems='center'>
                    <Grid container className={classes.title} justify="center" alignItems='center'>
                        <Grid item >
                            <a id="growers-section"></a>
                            <Typography ><h3>Wines</h3></Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" className={classes.winesContainer} alignItems='center' justify='center' >
                        <Typography color="secondary">Coming soon...</Typography>
                    </Grid>
                    {/* <Grid item direction="column" className={classes.gridFlexGrow} alignItems='center' justify='center' >
                    </Grid> */}
                    {/* <Grid container className={classes.growersRoot} justify="center" display='flex' alignItems='center'>
                        {this.getGrower(classes)}
                        {this.getGrower(classes)}
                        {this.getGrower(classes)}
                        {this.getGrower(classes)}
                    </Grid> */}
                </Grid>
                <Grid name="contact-section" id="contact-section" direction="column" justify="center" alignItems='center' container className={classes.fullHeightContainer}>
                    <Grid item className={classes.logo} justify="center" display='flex' alignItems='center'>
                    </Grid>
                    <Grid container className={classes.contactOverlay} direction="column" justify="center" display='flex' alignItems='center'>
                        <Grid container className={classes.title} justify="center" alignItems='center'>
                            <Grid item >
                                <Typography className={classes.blackOnSmall}><h3>Contact</h3></Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.blackOnSmall}>
                                Acid Wines Stockholm Handelsbolag<br />
                                PARKGATAN 11<br />
                                59232 Vadstena<br />
                                Östergötlands län<br />
                                <br />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <a href="https://www.facebook.com/acidwines"><FaFacebookSquare size="30" className={classes.blackOnSmall} /></a>
                            <a href="https://www.instagram.com/acid.wines"><FaInstagram size="30" className={classes.blackOnSmall} /></a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal disableAutoFocus={true} open={this.state.modalOpen}>
                <Paper className={classes.modalStyle}>
                    <Typography>
                        <h3>Welcome To Acid Wines</h3>
                        This content is suitable for persons over 25 years of age.<br /><br /></Typography>
                    <Button color='default' variant='outlined' onClick={() => this.closeModal()}>OK, I'm over 25!</Button>
                </Paper>
            </Modal>
        </div>);
    }

    closeModal() {
        this.setState({ modalOpen: false });
        setTimeout(() => this.setState({ showFirstItem: true }), 0);
        setTimeout(() => this.setState({ showSecondItem: true }), 500);
        setTimeout(() => this.setState({ showThirdItem: true }), 1200);
    }

    getGrower(classes) {
        return (<Grid item item xs={12} sm={6}>
            <Card square className={classes.card}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={Grower1}
                        title="Paella dish"
                    />
                    <CardContent>
                        <Typography>
                            Bodegas Baron dates back to 1805, but the oldest solera has traces of 400-year-old wine in it. This bodega was bought in 1985 by Enrique Perezs grandfather. The history of this family and Bodega is pretty unusual in the region. Enriques grandfather, locally called ‘Xixarito’-which means little pea, was an orphan. With no money in his pocket he started to do various and hard labour work already as a kid. But all the time dreaming of making sherry.
    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary">
                        Share
        </Button>
                    <Button size="small" color="primary">
                        Learn More
        </Button>
                </CardActions>
            </Card>

        </Grid>);
    }
}

export default withStyles(styles)(Home);
