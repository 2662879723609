import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Image from '../img/acidwines.png';
import Scrollspy from 'react-scrollspy'
import { Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
        maxWidth: 1200,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
    },
    appBarGrid: {
        flexGrow: 1,
        width: "100%",
        maxWidth: 1200,
    },
});

class NavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    scrollTo(elementName) {
        scroller.scrollTo(elementName, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        const { classes } = this.props;

        const sideList = (
            <div className={classes.list}>
                <List>
                    <ListItem button key='Home' onClick={() => this.scrollTo("home-section")}>
                        {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
                        <ListItemText primary='Home' />
                    </ListItem>
                    <ListItem button key='Wines' onClick={() => this.scrollTo("growers-section")}>
                        {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
                        <ListItemText primary='Wines' />
                    </ListItem>
                    <ListItem button key='Contact' onClick={() => this.scrollTo("contact-section")}>
                        {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
                        <ListItemText primary='Contact' />
                    </ListItem>
                </List>
                {/* <Divider /> */}
            </div>
        );

        const sideList2 = (
            <div>
                <Paper elevation={0}>
                    <Button key='Home' onClick={() => this.scrollTo("home-section")}>Home</Button>
                    <Button key='Wines' onClick={() => this.scrollTo("growers-section")}>Wines</Button>
                    <Button key='Contact' onClick={() => this.scrollTo("contact-section")}>Contact</Button>
                </Paper>
            </div>
        );


        return (
            <div className={classes.root}>
                <AppBar position="fixed" dense className={classes.appBar}>
                    <Grid container direction="column" alignItems='center' justify='center'>
                        <Toolbar variant="dense" className={classes.appBarGrid}>
                            <Hidden xsDown>{sideList2}</Hidden>
                            <Hidden smUp><IconButton className={classes.menuButton} onClick={this.toggleDrawer('left', true)} aria-label="Menu">
                                <MenuIcon />
                            </IconButton>
                            </Hidden>
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                            </Typography>
                            <img src={Image} style={{ margin: 5 }} width="45vh" ></img>
                        </Toolbar>
                    </Grid>
                    <SwipeableDrawer
                        open={this.state.left}
                        onClose={this.toggleDrawer('left', false)}
                        onOpen={this.toggleDrawer('left', true)}>
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.toggleDrawer('left', false)}
                            onKeyDown={this.toggleDrawer('left', false)}>
                            {sideList}
                        </div>
                    </SwipeableDrawer>
                </AppBar>
            </div>
        );
    }
}

NavMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavMenu);